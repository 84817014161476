<template>
  <section class="module">
    <div class="container">
      <div class="row">
        <div class="col-md-4 m-auto text-center">
          <h1>Sorry!</h1>
          <h4>The Page you tried cannot be found</h4>
          <span class="heading">404</span>
          <p> Please, click the button below and visit home page.</p>
          <a
            class="btn btn-circle btn-lg btn-dark"
            href="/"
            style="display: flex;
  justify-content: center;">Home</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PageNotFoundComponent',
};
</script>

<style>

</style>
