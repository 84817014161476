<template>
  <div class="home">
    <HeaderComponent />
    <PageNotFound />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from '@/components/partials/Header.vue';
import PageNotFound from '@/components/PageNotFound.vue';
import FooterComponent from '@/components/partials/Footer.vue';

export default {
  name: 'NotFoundComponent',
  components: {
    HeaderComponent,
    PageNotFound,
    FooterComponent,
  },
};
</script>
