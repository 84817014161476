<template>
  <header class="header header-center undefined">
    <div class="container-fluid">
      <!-- Logos-->
      <div class="inner-header">
        <a class="inner-brand" href="/">
          <img
            class="brand-dark logoECFix"
            src="../../assets/images/logo.png"
            alt="Elevate with Everest Computing"
          >
          <img
            class="brand-light logoEC"
            src="../../assets/images/logo.png"
            alt="Elevate with Everest Computing"
          >
          <!-- Or simple text-->
          <!-- Core-->
        </a>
      </div>
      <!-- Navigation-->
      <div class="inner-navigation collapse">
        <div class="inner-navigation-inline">
          <div class="inner-nav">
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              <!-- <li>
                <a href="#team">Team</a>
              </li> -->
              <li>
                <a href="#WorkPro">Work Process</a>
              </li>
              <!--  <li>
                <a href="#contact">Contact</a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <!-- Mobile menu-->
      <div class="nav-toggle">
        <a href="#" data-toggle="collapse" data-target=".inner-navigation">
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
};
</script>

<style>
</style>
