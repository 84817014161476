<template>
  <!-- Footer-->
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-4 col-xl-4">
          <!-- Text widget-->
          <aside class="widget widget_text">
            <div class="textwidget">
              <p>
                <img src="../../assets/images/logo-white.png">
              </p>
              <p>
                We collaborate with our clients to deliver
                best services that will satisfy audience needs and surpass the business goals.
              </p>
            </div>
          </aside>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-4">
          <!-- Social Icons -->
          <aside class="widget widget_recent_entries">
            <div class="widget-title">
              <h5>Contact</h5>
            </div>
            <i class="fa fa-envelope" />&nbsp;&nbsp;
            <a href="mailto:contact@everestcomputing.ca">
              contact@everestcomputing.ca
            </a>
            <br>
            <i class="fa fa-lg fa-mobile" />&nbsp;&nbsp;
            <a href="tel:437-882-0007">
             437-882-0007
            </a>
            <br>
            <ul class="social-icons">
              <li><a
                href="https://ecomputing.ca/web-fb"
                target="_blank"
              ><i class="fa fa-facebook" /></a></li>
              <li><a
                href="https://ecomputing.ca/web-tw"
                target="_blank"
              ><i class="fa fa-twitter" /></a></li>
              <li><a
                href="https://ecomputing.ca/web-in"
                target="_blank"
              ><i class="fa fa-instagram" /></a></li>
              <li><a
                href="https://ecomputing.ca/web-li"
                target="_blank"
              ><i class="fa fa-linkedin" /></a></li>

            </ul>
          </aside>
        </div>
        <!-- <div class="col-md-6 col-lg-3 col-xl-3"> -->
        <!-- Twitter widget-->
        <!-- <aside class="widget twitter-feed-widget">
            <div class="widget-title">
              <h5>Twitter Feed</h5>
            </div>
            <a
              class="twitter-timeline"
              data-lang="en"
              data-dnt="true"
              data-theme="dark"
              href="https://twitter.com/EverestComp?ref_src=twsrc%5Etfw"
            >Tweets by EverestComp</a>
          </aside>
        </div> -->
        <div class="col-md-6 col-lg-4 col-xl-4">
          <!-- Newsletter widget-->
          <aside class="widget twitter-feed-widget">
            <div class="widget-title">
              <h5>Newsletter</h5>
            </div>
            <div class="klaviyo-form-Mzyf6S" />
          </aside>
        </div>
      </div>
    </div>
    <div class="footer-copyright">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="text-center">
              <span class="copyright">© 2023 Everest Computing, All Rights Reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer end-->
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style>
</style>
