<template>
  <div class="home">
    <HeaderComponent />
    <HomeMain />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from '@/components/partials/Header.vue';
import HomeMain from '@/components/HomeMain.vue';
import FooterComponent from '@/components/partials/Footer.vue';

export default {
  name: 'HomeComponent',
  components: {
    HeaderComponent,
    HomeMain,
    FooterComponent,
  },
};
</script>
