<template>
  <div>
    <!-- Hero Image -->
    <section
      class="module-header full-height parallax bg-dark bg-dark-60"
      data-background="/assets/images/EverestComputing.jpg"
    >
      <div class="container mainText">
        <div class="row">
          <div class="col-md-12">
            <h1
              class="h1"
              style="color:#303393;"
            >Elevate With</h1>
            <h1
              class="h1 m-b-15"
              style="color:#ec804b;"
            >Everest Computing</h1>
            <p class="m-b-30" />
            <p>
              <a
                class="btn btn-lg btn-circle btn-brand mainButton"
                href="#services"
              >Learn More</a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- Alt Services-->
    <section
      class="module module-gray module-divider-bottom"
      id="services"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8 m-auto">
            <div class="module-title text-center">
              <h2>Services</h2>
              <p class="font-serif">We provide a complete list of best digital services.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="m-t-30" />
            <div class="icon-box icon-box-left">
              <div class="icon-box-icon">
                <span class="icon fa fa-clone" />
              </div>
              <div class="icon-box-title">
                <h6>Web Designs</h6>
              </div>
              <div class="icon-box-content">
                <p>
                  Our UX design services combine accessibility and usability
                  with your content and marketing goals to improve the experience
                  customers have with your organization.
                </p>
              </div>
            </div>
            <div class="icon-box icon-box-left">
              <div class="icon-box-icon">
                <span class="icon fa fa-cart-arrow-down" />
              </div>
              <div class="icon-box-title">
                <h6>E-Commerce Solutions</h6>
              </div>
              <div class="icon-box-content">
                <p>
                  Our customized ecommerce website development solutions
                  are built to highlight products, promote buying and to
                  be capable of expansion when the need arises
                </p>
              </div>
            </div>
            <div class="icon-box icon-box-left">
              <div class="icon-box-icon">
                <span class="icon fa fa-cogs" />
              </div>
              <div class="icon-box-title">
                <h6>Content Management</h6>
              </div>
              <div class="icon-box-content">
                <p>
                  Content management systems make it easy for you to manage
                  the content of your website, ensuring that your message is
                  always current and accurate.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 hidden-sm-down">
            <div class="text-center">
              <img
                src="../assets/images/iphone_6.png"
                alt="Services"
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="m-t-30" />
            <div class="icon-box icon-box-left">
              <div class="icon-box-icon">
                <span class="icon fa fa-code" />
              </div>
              <div class="icon-box-title">
                <h6>Custom Programming</h6>
              </div>
              <div class="icon-box-content">
                <p>
                  Our programmers will work with your
                  business to create a custom programming solution
                  that makes sense and achieves your goal.
                </p>
              </div>
            </div>
            <div class="icon-box icon-box-left">
              <div class="icon-box-icon">
                <span class="icon fa fa-server" />
              </div>
              <div class="icon-box-title">
                <h6>Web Hosting</h6>
              </div>
              <div class="icon-box-content">
                <p>
                  We provide web hosting service ensures a hassle-free experience
                  for business owners, so they can efficiently focus their time
                  and effort on their businesses.
                </p>
              </div>
            </div>
            <div class="icon-box icon-box-left">
              <div class="icon-box-icon">
                <span class="icon fa fa-envelope" />
              </div>
              <div class="icon-box-title">
                <h6>E-mail Management</h6>
              </div>
              <div class="icon-box-content">
                <p>
                  Get a custom domain email for your business.
                  Create unique and professional email addresses for your
                  employees with your company domain.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Alt Services end-->
    <!-- Team-->
    <!-- <section
      class="module"
      id="team"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8 m-auto">
            <div class="module-title text-center">
              <h2>Team</h2>
              <p class="font-serif">We’re the best professionals in this field.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="team-item m-b-30">
              <div class="team-image">
                <img
                  src="../assets/images/preet.png"
                  alt
                >
                <div class="team-content">
                  <h5>Preet Brar</h5>
                  <p>Application Developer</p>
                </div>

              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="team-item m-b-30">
              <div class="team-image">
                <img
                  src="../assets/images/raj.png"
                  alt
                >
                <div class="team-content">
                  <h5>Raj Yogi</h5>
                  <p>Web Developer</p>
                </div>

              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="team-item m-b-30">
              <div class="team-image">
                <img
                  src="../assets/images/mohit.jpeg"
                  alt
                >
                <div class="team-content">
                  <h5>Mohit Gir</h5>
                  <p>Full Stack Developer</p>
                </div>

              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="team-item m-b-30">
              <div class="team-image">
                <img
                  src="../assets/images/pankaj.png"
                  alt="Sales Representative"
                >
                <div class="team-content">
                  <h5>Pankaj Saini</h5>
                  <p>Sales Manager</p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- Team end-->
    <!-- Company-->
    <section class="module module-gray" id="WorkPro">
      <div class="container">
        <div class="row">
          <div class="col-md-8 m-auto">
            <div class="module-title text-center">
              <h2>Work Process</h2>
              <p class="font-serif">An eye for detail makes our works excellent.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <p>
              <img
                src="../assets/images/work_process.png"
                alt
              >
            </p>
          </div>
          <div class="col-md-8">
            <!-- Accordion-->
            <div
              class="accordion panel-group"
              id="accordion1"
            >
              <div class="card">
                <div class="card-header">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion1"
                    href="#collapse1"
                    aria-expanded="false"
                  >
                    <i class="fa fa-tasks" />Analysis &amp; Planning
                  </a>
                </div>
                <div
                  class="collapse show"
                  id="collapse1"
                >
                  <div class="card-body">
                    <h5>Intial Research</h5>
                    <ul>
                      <li>Analyse Client's requirements and
                        review current analytics data and trends.</li>
                      <li>Examine Competitor's Websites and
                        identify new website's purpose, objective and audience.</li>
                    </ul>
                    <h5>Scope</h5>
                    <ul>
                      <li>Identify required technical functinality.</li>
                      <li>Define and document technical scope in details.</li>
                    </ul>
                    <h5>Architecture</h5>
                    <ul>
                      <li>Asssemble contant types and define content hierarchy and flow.</li>
                      <li>Create page outlines, sitemap and architecture.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion1"
                    href="#collapse2"
                    aria-expanded="false"
                  >
                    <i class="fa fa-code" /> Design &amp; Development
                  </a>
                </div>
                <div
                  class="collapse"
                  id="collapse2"
                >
                  <div class="card-body">
                    <h5>Wireframe</h5>
                    <ul>
                      <li>Establish a visual hierarchy
                        and layout pages using a responsive grid.</li>
                      <li>Produce a static or live wireframe.</li>
                    </ul>
                    <h5>Design</h5>
                    <ul>
                      <li>Review client's brand standards and
                        determine overall visual feel.</li>
                      <li>Identify colors, fonts and create
                        visual assets like button, CTA etc.</li>
                      <li>Design homepage using wireframes and make final design files.</li>
                    </ul>
                    <h5>Development</h5>
                    <ul>
                      <li>Use graphic elements to create an actual website.</li>
                      <li>Install frameworks and CMS at this point and set-up server smoothly.</li>
                      <li>Add special features and interactivity of website.</li>
                      <li>Implement content including text, visual and edit where required.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion1"
                    href="#collapse3"
                    aria-expanded="false"
                  >
                    <i class="fa fa-rocket" /> Test &amp; Launch
                  </a>
                </div>
                <div
                  class="collapse"
                  id="collapse3"
                >
                  <div class="card-body">
                    <h5>Testing</h5>
                    <ul>
                      <li>Test performance on different devices and platforms.</li>
                      <li>Track and correct bugs if there is any.</li>
                      <li>Conduct user testing.</li>
                      <li>Optimize and adjust as needed.</li>
                    </ul>
                    <h5>Launch final website on the server</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Company end-->
    <!-- Testimonials-->
    <!-- <section
      class="module parallax bg-dark bg-dark-30"
      data-background="https://marketplace.canva.com/MACVidFfwsg/1/screen/canva-background-texture%2C-hexagon%2C-grid%2C-3d%2C-geometric%2C-web-MACVidFfwsg.jpg"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6 m-auto">
            <div class="tms-slides owl-carousel">
              <div class="tms-item">
                <div class="tms-icons">
                  <h2>
                    <span class="fa fa-comment-o"></span>
                  </h2>
                </div>
                <div class="tms-content">
                  <blockquote>
                    <p>
                      “1If you want to know what a man's like,
                      take a good look at how he treats his inferiors, not his equals.”
                    </p>
                  </blockquote>
                </div>
                <div class="tms-author">
                  <span>J.K. Rowling</span>
                </div>
              </div>
              <div class="tms-item">
                <div class="tms-icons">
                  <h2>
                    <span class="fa fa-comment-o"></span>
                  </h2>
                </div>
                <div class="tms-content">
                  <blockquote>
                    <p>
                      “To be yourself in a world that is constantly
                      trying to make you something else is the greatest accomplishment.”
                    </p>
                  </blockquote>
                </div>
                <div class="tms-author">
                  <span>Ralph Waldo Emerson</span>
                </div>
              </div>
              <div class="tms-item">
                <div class="tms-icons">
                  <h2>
                    <span class="fa fa-comment-o"></span>
                  </h2>
                </div>
                <div class="tms-content">
                  <blockquote>
                    <p>
                      “Imperfection is beauty, madness is genius and
                      it's better to be absolutely ridiculous than absolutely boring.”
                    </p>
                  </blockquote>
                </div>
                <div class="tms-author">
                  <span>Marilyn Monroe</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- Testimonials end-->
    <!-- Contact-->
    <!-- <section
      class="module"
      id="contact"
    >
       <div class="container">
        <div class="row">
          <div class="col-md-8 m-auto">
            <div class="module-title text-center">
              <h2>Let's Talk</h2>
              <p class="font-serif">Please feel free to get in touch anytime.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 m-auto">
            <form
              id="contact-form"
              method="post"
              novalidate
            >
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      name="name"
                      placeholder="Name"
                      required
                    >
                    <p class="help-block text-danger"></p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      required
                    >
                    <p class="help-block text-danger"></p>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      required
                    >
                    <p class="help-block text-danger"></p>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      name="message"
                      placeholder="Message"
                      rows="12"
                      required
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="text-center">
                    <input
                      class="btn btn-round btn-brand"
                      type="submit"
                      value="Send"
                    >
                  </div>
                </div>
              </div>
            </form>
      <div
        class="ajax-response text-center"
        id="contact-response"
      />
  </div>
  </div>
  </div>
  </section> -->
    <!-- Contact end-->
  </div>
</template>

<script>
export default {
  name: 'HomeMainComponent',
};
</script>

<style>
</style>
